import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}


const Image = (props) => {
  const { image, title } = props;
  
  return (
    <img
      src={urlFor(image)}
      alt={title}
      className="home-desktop__media"
    />
  )
}

export default Image