import { getFile } from "@sanity/asset-utils";
import sanityClient from "../../client";

const Video = (props) => {

  const { video } = props;
  const src = getFile(video, sanityClient.config());

  return (
    <video
      src={src.asset.url}
      autoPlay={true}
      loop={true}
      background="true"
      playsInline={true}
      muted={true}
      className="home-desktop__media"
    />
  );
};

export default Video;
