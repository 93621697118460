import { useEffect } from "react";
import { motion } from "framer-motion";
import { Link } from "react-router-dom";

const Error = (props) => {
  const { setLogoIsSmall } = props;

  useEffect(() => {
    setLogoIsSmall(true);
  }, [setLogoIsSmall]);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="error__outer-wrapper"
    >
      <h1>Error 404: We can't find the page you're looking for...</h1>
      <Link to={"/"} className="terms__back-button">
        &larr; Return Home
      </Link>
    </motion.main>
  );
};

export default Error;
