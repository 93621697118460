import { useEffect, useState } from "react";
import { motion } from "framer-motion";
import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../hooks/usePortableTextComponents";
import sanityClient from "../client";

import Loading from "../components/global/Loading";

import "./Information.scss";

const Information = (props) => {
  const { setLogoIsSmall, isInfo, setIsInfo } = props;
  const [pageData, setPageData] = useState(null);

  useEffect(() => {
    setLogoIsSmall(true);
    if(!isInfo){
      setIsInfo(true);
    }
  },[]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "information"][0]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="info__outer-wrapper"
      >
        <PortableText value={pageData.bio} components={UnderlineLink} />
        <h1><strong className="strong">Contact</strong></h1>
        <a href={`mailto:${pageData.emailAddress}`}>Email</a>
        <div className="info__socials">
        {pageData.socials &&
        pageData.socials.map((link, index)=>{
          return(
            <a href={link.entryLink} target="_blank" rel="noreferrer" key={index} >{link.entryName}</a>
          )
        })}
        </div>
      </motion.main>
    );
  } else {
    return <Loading />;
  }
};

export default Information;
