import { useRef, useState } from "react";
import { Navigation, Pagination } from "swiper/modules";
import { Swiper, SwiperSlide, useSwiper } from "swiper/react";
import { PortableText } from "@portabletext/react";
import { UnderlineLink } from "../../hooks/usePortableTextComponents";

import "swiper/css";
import "./Gallery.scss";

import Image from "./Image";
import Vimeo from "./Vimeo";

const Gallery = (props) => {
  const { pageData } = props;
  // const swiper = useSwiper();
  const [_, setInit] = useState();
  const prevRef = useRef(null);
  const nextRef = useRef(null);
  const [infoOpen, setInfoOpen] = useState(false);

  const handleClick = () => {
    setInfoOpen((prev) => !prev);
  };

  return (
    <>
      <div className="gallery__outer-wrapper">
        <Swiper
          modules={[Navigation, Pagination]}
          spaceBetween={50}
          slidesPerView={1}
          pagination={{
            type: "fraction",
            el: '.swiper-custom-pagination'
          }}
          loopedSlides={true}
          loop={true}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onInit={() => setInit(true)}
        >
          {pageData.gallery &&
            pageData.gallery.map((slide, index) => {
              if (slide._type === "image") {
                return (
                  <SwiperSlide key={index}>
                    <div className="gallery__media-wrapper">
                      <Image image={slide} title={pageData.title} />
                    </div>
                  </SwiperSlide>
                );
              } else {
                return (
                  <SwiperSlide key={index}>
                    <div className="gallery__media-wrapper">
                      <Vimeo video={slide.vimeoMedia} title={pageData.title} />
                    </div>
                  </SwiperSlide>
                );
              }
            })}
        </Swiper>
        <button
          className="gallery__arrow next"
          ref={nextRef}
        >
          &rarr;
        </button>
        <button className="gallery__arrow prev" ref={prevRef}>
          &larr;
        </button>
      </div>
      <div
        className={`project__info-outer-wrapper ${infoOpen ? "active" : ""}`}
      >
        <div className="project__info-inner-wrapper">
          <div className="project__info-inner-inner">
            <PortableText
              value={pageData.description}
              components={UnderlineLink}
            />
          </div>
        </div>
      </div>
      <div className="project__title-wrapper">
        {pageData.title} – {pageData.client}, <span className="swiper-custom-pagination"></span>
        <br />
        <button onClick={handleClick}>
          {infoOpen ? "Close" : "Read More"}
        </button>
      </div>
    </>
  );
};

export default Gallery;
