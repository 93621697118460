import { useRef, useState, useEffect } from "react";
import Plyr from "plyr-react";
import "./plyr-styles/plyr.scss";

const Video = (props) => {

  // const { video, title } = props;
  // const vimeoEmbed = useRef();
    const { video } = props;

  const [plyrProps, setPlyrProps] = useState({});

  useEffect(() => {
    setPlyrProps({
      source: {
        type: "video",
        sources: [
          {
            src: video,
            provider: "vimeo",
          },
        ],
      },
      options: {
        controls: ["play", "mute", "progress", "fullscreen"],
      },
    });
  }, [video]);

  return (
    <>
      <div 
      className="gallery__video-outer"
      >
        <Plyr {...plyrProps} />
        {/* <iframe
          src={`https://player.vimeo.com/video/${video}?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479`}
          frameborder="0"
          allow="autoplay; fullscreen; autopause"
          allowfullscreen
          className="gallery__video-inner"
          title={title}
          ref={vimeoEmbed}
        ></iframe> */}
      </div>
      <script src="https://player.vimeo.com/api/player.js"></script>
    </>
  );
};


export default Video;
