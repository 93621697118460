import { useState, useRef } from "react";
import Video from "./Video";
import Image from "./Image";
import { Link } from "react-router-dom";
import { motion, useDragControls } from "framer-motion"

const HomeDesktopItem = (props) => {

  const { project, setHoveredTitle } = props;
  const controls = useDragControls()
  const wrapper = useRef();
  const [isActive ] = useState(false);
  const [position ] = useState({
    left: Math.floor(Math.random() * (window.innerWidth - (window.innerWidth / 4))),
    top: Math.floor(Math.random() * (window.innerHeight - (window.innerHeight / 4))),
    z: Math.floor(Math.random() * 10),
  });

  const handleEnter = () => {
    setHoveredTitle(project.title);
  }

  const handleLeave = () => {
    setHoveredTitle(null);
  }

  const handlePointerDown = () => {
    wrapper.current.style.zIndex = 11
  }

  const handlePointerUp = () => {
    wrapper.current.style.zIndex = Math.floor(Math.random() * 10);
  }
  
  return (
    <motion.div
      ref={wrapper}
      className={`home-desktop__item-wrapper ${isActive ? "active" : ""}`}
      style={{
        left: `${position.left}px`,
        top: `${position.top}px`,
        zIndex: position.z,
      }}
      onMouseEnter={handleEnter}
      onMouseLeave={handleLeave}
      onPointerDown={handlePointerDown}
      onPointerUp={handlePointerUp}
      drag 
      dragControls={controls}

    >
      <Link to={`projects/${project.slug.current}`} className="home-desktop__link">{'\u2197'}</Link>
      {project.previewVideo?(
        <Video video={project.previewVideo} />
      ):(
        <Image image={project.heroImage} title={project.title}/>
      )}
    </motion.div>
  );
};

export default HomeDesktopItem;
