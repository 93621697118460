const Filters = () => {

    const filterItems = document.querySelectorAll('.projects__filter-item');
    const projects = document.querySelectorAll('.projects__project-title')
  const handleClick = (e) => {
    
    const value = e.target.dataset.value;
    filterItems.forEach((filter)=>{
        filter.classList.remove('disabled');
    });
    projects.forEach((project)=>{
        project.classList.remove('disabled');
        if(value !== 'all'){
            if(!project.classList.contains(value)){
                project.classList.add('disabled')
            }
        }
    });
    e.target.classList.add('disabled');
    console.log(value);
  };

  return (
    <div className="projects__filters-wrapper">
        <div>Filters:</div>
        
      <button
        data-value="direction"
        className="projects__filter-item"
        onClick={handleClick}
      >
        Direction
      </button>
      <button
        data-value="production"
        className="projects__filter-item"
        onClick={handleClick}
      >
        Production
      </button>
      <button
        data-value="commercial"
        className="projects__filter-item"
        onClick={handleClick}
      >
        Commercial
      </button>
      <button
        data-value="documentary"
        className="projects__filter-item"
        onClick={handleClick}
      >
        Documentary
      </button>
      <button
        data-value="all"
        className="projects__filter-item disabled"
        onClick={handleClick}
      >
        All
      </button>
    </div>
  );
};

export default Filters;
