import { Route, Routes, useLocation } from "react-router-dom";
import { useState, useEffect } from "react";
import { AnimatePresence } from "framer-motion";

import Logo from "./components/global/Logo";
import Nav from "./components/global/Nav";

import Home from "./views/Home";
import Information from "./views/Information";
import Projects from "./views/Projects";
import Project from "./views/Project";
import Error from "./views/Error";

import "./styles/global-styles.scss";

function App() {
  const location = useLocation();

  useEffect(() => {
    const appHeight = () => {
      const doc = document.documentElement;
      doc.style.setProperty("--app-height", `${window.innerHeight}px`);
    };
    window.addEventListener("resize", appHeight);
    appHeight();
    return () => window.removeEventListener("resize", appHeight);
  }, []);

  const [logoIsSmall, setLogoIsSmall] = useState(false);
  const [isInfo, setIsInfo] = useState(false);

  return (
    <AnimatePresence>
      <Logo logoIsSmall={logoIsSmall} />
      <Nav
        {...{
          isInfo,
          setIsInfo
        }}
      />
      <Routes location={location} key={location.pathname}>
        <Route
          element={
            <Home
              key={"home"}
              {...{
                setLogoIsSmall,
                setIsInfo
              }}
            />
          }
          path="/"
        />
        <Route
          element={
            <Information
              key={"information"}
              {...{
                setLogoIsSmall,
                isInfo,
                setIsInfo
              }}
            />
          }
          path="/information"
        />
        <Route
          element={
            <Projects
              key={"projects"}
              {...{
                setLogoIsSmall,
              }}
            />
          }
          path="/projects"
        />
        <Route
          element={
            <Project
              key={"project"}
              {...{
                setLogoIsSmall,
              }}
            />
          }
          path="/projects/:slug"
        />
        <Route
          element={
            <Error
              key={"error"}
              {...{
                setLogoIsSmall,
              }}
            />
          }
          path="*"
        />
      </Routes>
    </AnimatePresence>
  );
}

export default App;
