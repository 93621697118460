import { useEffect, useState } from "react";
import HomeDesktop from "../components/home/HomeDesktop";
import HomeMobile from "../components/home/HomeMobile";
import Loading from "../components/global/Loading";

import sanityClient from "../client";
import useWindowSize from "../hooks/useWindowSize";


const Home = (props) => {
  const { setLogoIsSmall } = props;
  const [pageData, setPageData] = useState(null);
  // const windowSize = useWindowSize();

  const [ isMobile, setIsMobile ] = useState(false);

  useEffect(()=>{
    const handleResize = () => {
      if(window.innerWidth < 750){
        setIsMobile(true);
      } else {
        setIsMobile(false)
      }
    }
    handleResize();
    window.addEventListener("resize", handleResize);
  },[])

  useEffect(()=>{
    setLogoIsSmall(false);
  },[]);

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "projects"]`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if(pageData){
    if(!isMobile){
      return <HomeDesktop pageData={pageData}/>;
    } else {
      return <HomeMobile pageData={pageData}/>;
    }
    
  } else {
    return <Loading />;
  }

}

export default Home