import { motion } from "framer-motion";
import HomeMobileItem from "./HomeMobileItem";

import "./HomeMobile.scss";

const HomeMobile = (props) => {

    const { pageData } = props;

  return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="home-mobile__outer-wrapper"
      >
        {pageData &&
        pageData.map((project, index)=>{

          if(project.featuredProject){
            return(
              <HomeMobileItem 
              project={project} 
              key={index} 
              index={index}
              />
            )
          } else {
            return;
          }

        })}
      </motion.main>
  )
}

export default HomeMobile