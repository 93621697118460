import { useState, useEffect } from "react";
import { motion } from "framer-motion";
import HomeDesktopItem from "./HomeDesktopItem";

import "./HomeDesktop.scss";

const HomeDesktop = (props) => {
  const { pageData } = props;
  const [hoveredTitle, setHoveredTitle] = useState(null);

  const randomisePosition = (elm) => {
    elm.style.left = `${Math.floor(
      Math.random() * (window.innerWidth - (window.innerWidth / 4))
    )}px`;
    elm.style.top = `${Math.floor(
      Math.random() * (window.innerHeight - window.innerHeight / 8)
    )}px`;
    elm.style.zIndex = Math.floor(Math.random() * 10);

  };

  useEffect(() => {
    const projects = document.querySelectorAll(".home-desktop__item-wrapper");
    const interval = setInterval(() => {
      let show = Math.floor(Math.random() * projects.length);

      if (!projects[show].classList.contains("active")) {
        projects[show].classList.add("active");
        setTimeout(() => {
          projects[show].classList.remove("active");
          setTimeout(() => {
            randomisePosition(projects[show]);
          }, 400);
        }, 10000);
      }
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <motion.main
      initial={{ opacity: 0 }}
      animate={{ opacity: 1, transition: { duration: 0.4 } }}
      exit={{ opacity: 0, transition: { duration: 0.4 } }}
      className="home-desktop__outer-wrapper"
    >
      {pageData &&
        pageData.map((project, index) => {
          if (project.featuredProject) {
            return (
              <HomeDesktopItem
                project={project}
                key={index}
                index={index}
                setHoveredTitle={setHoveredTitle}
              />
            );
          } else {
            return;
          }
        })}
      <div className="home-desktop__title-wrapper">{hoveredTitle}</div>
    </motion.main>
  );
};

export default HomeDesktop;
