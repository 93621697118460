import { useEffect, useRef, useState } from "react";
import { motion } from "framer-motion";
import sanityClient from "../client";

import Filters from "../components/Projects/Filters";
import ProjectItem from "../components/Projects/ProjectItem";
import Loading from "../components/global/Loading";

import "./Projects.scss";

const Projects = (props) => {
  const { setLogoIsSmall } = props;
  const [ pageData, setPageData] = useState(null);
  const [ currentImage, setCurrentImage] = useState(null);
  const [ currentVideo, setCurrentVideo ] = useState(null);

  const imageWrapper = useRef();
  const videoWrapper = useRef();

  useEffect(() => {
    setLogoIsSmall(true);
  });

  useEffect(() => {
    sanityClient
      .fetch(`*[_type == "projects"]|order(orderRank)`)
      .then((data) => {
        setPageData(data);
      })
      .catch();
  }, []);

  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="projects__outer-wrapper"
      >
        <Filters />
        <div className="projects__media-wrapper">
          <img src={currentImage} alt="" ref={imageWrapper} className={`projects__media-image ${currentImage && !currentVideo?'active':''}`}/>
          <video src={currentVideo} ref={videoWrapper} loop muted autoPlay playsInline background="true" className={`projects__media-video ${currentVideo?'active':''}`} />
        </div>
        {pageData &&
        pageData.map((project, index)=>{
          return(
            <ProjectItem {...{
              project,
              index,
              setCurrentImage,
              setCurrentVideo,
            }} key={index}/>
          )
        })}
      </motion.main>
    );
  } else {
    return <Loading />;
  }
}

export default Projects