import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { motion } from "framer-motion";
import sanityClient from "../client";

import Gallery from "../components/project/Gallery";
import Loading from "../components/global/Loading";

import "./Project.scss";

const Project = (props) => {
  const { setLogoIsSmall } = props;
  const { slug } = useParams();
  const [pageData, setPageData] = useState(null);


  useEffect(() => {
    sanityClient
      .fetch(`*[slug.current == $slug]`, { slug })
      .then((data) => {
        setPageData(data[0]);
      })
      .catch();
  }, [slug]);

  useEffect(() => {
    setLogoIsSmall(true);
  }, []);



  if (pageData) {
    return (
      <motion.main
        initial={{ opacity: 0 }}
        animate={{ opacity: 1, transition: { duration: 0.4 } }}
        exit={{ opacity: 0, transition: { duration: 0.4 } }}
        className="project__outer-wrapper"
      >
        <Gallery pageData={pageData}/>
      </motion.main>
    );
  } else {
    return <Loading />;
  }
};

export default Project;
