import { useState, useRef, useEffect } from "react";
import Video from "./Video";
import Image from "./Image";
import { Link } from "react-router-dom";
import { motion, useDragControls } from "framer-motion"

const HomeMobileItem = (props) => {

  const { project } = props;

  
  return (
    <div
      className={`home-mobile__item-wrapper`}
    >
      <Link to={`projects/${project.slug.current}`} className="home-mobile__link">
      {project.previewVideo?(
        <Video video={project.previewVideo} />
      ):(
        <Image image={project.heroImage} title={project.title}/>
      )}
      <div className="home-mobile__title">{project.title}</div>
      </Link>
    </div>
  );
};

export default HomeMobileItem;
