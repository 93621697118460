import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

import "./Nav.scss";

const Nav = (props) => {

  const { isInfo, setIsInfo} = props;
  const navigate = useNavigate();

  const handleCloseInfo = () => {
    setIsInfo(false);
    navigate(-1);
  }

  return (
    <>
    <div className="nav__vertical-wrapper left">
        <Link to={"/projects"} className="nav__rotated left" onClick={()=>setIsInfo(false)}>Projects Index</Link>
    </div>

    <div className="nav__vertical-wrapper right">
        {isInfo === false?(
          <Link to={"/information"} className="nav__rotated right" onClick={()=>setIsInfo(true)}>Info / Contact</Link>
        ):(
          <button className="nav__rotated right" onClick={handleCloseInfo}>Close</button>
        )}
    </div>
    </>
  )
}

export default Nav