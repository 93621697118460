import { Link } from "react-router-dom";
import { getFile } from "@sanity/asset-utils";
import imageUrlBuilder from "@sanity/image-url";
import sanityClient from "../../client";
import { useEffect, useRef, useState } from "react";

const builder = imageUrlBuilder(sanityClient);

function urlFor(source) {
  return builder.image(source);
}

const ProjectItem = (props) => {
  const { project, setCurrentImage, setCurrentVideo } = props;
  const item = useRef();
  const [heroVideo, setHeroVideo] = useState(null);
  const [heroImage, setHeroImage] = useState(null);

  const [filters, setFilters] = useState([]);

  useEffect(() => {
    if (project.previewVideo !== undefined) {
      const videoUrl = getFile(project.previewVideo, sanityClient.config());
      setHeroVideo(videoUrl.asset.url);
    }

    if (project.heroImage !== null) {
      const imageUrl = urlFor(project.heroImage);
      setHeroImage(imageUrl);
    }
  }, []);

  useEffect(() => {
    let filterNames = [];
    if (project.projectFilters.direction) {
      filterNames.push("direction");
    }
        if (project.projectFilters.production) {
      filterNames.push("production");
    }
      if (project.projectFilters.commercial) {
      filterNames.push("commercial");
    }
      if (project.projectFilters.documentary) {
      filterNames.push("documentary");
    }
    setFilters(filterNames);
  },[]);

  const handleMouseEnter = () => {
    if(window.innerWidth > 750){
      if (heroVideo) {
        setCurrentVideo(heroVideo);
      } else if (heroImage) {
        setCurrentImage(heroImage);
      } else {
        setCurrentImage(null);
        setCurrentVideo(null);
      }
    }
 
  };

  const handleMouseLeave = () => {
    setCurrentImage(null);
    setCurrentVideo(null);
  };

  return (
    <Link
      ref={item}
      to={project.slug.current}
      className={`projects__project-title ${filters.join(" ")}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {project.title}
      <br />
      <span className="projects__project-client">{project.client}</span>
    </Link>
  );
};

export default ProjectItem;
