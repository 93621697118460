import { useFrame, useThree } from "@react-three/fiber";
import { PerspectiveCamera } from "@react-three/drei";
import Model from "./Model";

const CameraControls = (props) => {
  const { logoIsSmall } = props;
  const { camera, mouse } = useThree();

  useFrame(() => {
    
    camera.rotation.x += 0.05 * ( mouse.y - camera.rotation.x );
    camera.rotation.y += 0.05 * ( mouse.x - camera.rotation.y );
    
  });

  return (
    <PerspectiveCamera position={[0, 0, 0]} makeDefault>
      <Model logoIsSmall={logoIsSmall} />
    </PerspectiveCamera>
  );
};

export default CameraControls;
