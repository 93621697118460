import { Canvas } from "@react-three/fiber";
import CameraControls from "./CameraControls";
import { Link } from "react-router-dom";

import "./Logo.scss";

const Logo = (props) => {
  const { logoIsSmall } = props;

  return (
    <>
      <div
        className="logo__wrapper"
        style={{
          zIndex: logoIsSmall ? 999 : 5,
        }}
      >
        <Canvas
          flat
          onCreated={(state) => {
            if (state?.events?.connect) {
              if (typeof window !== "undefined") {
                state.events.connect(window);
              }
            }
          }}
          style={{ pointerEvents: "none" }}
        >
          <CameraControls logoIsSmall={logoIsSmall} />
        </Canvas>
      </div>
      {logoIsSmall ? <Link to={"/"} className="logo__link" /> : ""}
    </>
  );
};

export default Logo;
