import { useSpring, animated, config } from "@react-spring/three";
import { useGLTF } from "@react-three/drei";
import { CubeTextureLoader, MeshStandardMaterial } from "three";
import useWindowSize from "../../hooks/useWindowSize";

const Model = (props) => {
  const { logoIsSmall } = props;
  const { nodes } = useGLTF("/logo/AL.gltf");
  const windowSize = useWindowSize();

  const CubeTexture = new CubeTextureLoader().load([
    "/logo/px.jpg",
    "/logo/nx.jpg",
    "/logo/py.jpg",
    "/logo/ny.jpg",
    "/logo/pz.jpg",
    "/logo/nz.jpg",
  ]);

  const orangeMaterial = new MeshStandardMaterial({
    color: 0xffffff,
    metalness: 1,
    roughness: 0.1,
    envMap: CubeTexture,
    envMapIntensity: 1,
  });

  const { position } = useSpring({
    position:
      windowSize.width > 750
        ? logoIsSmall
          ? [0.1, 2.8, -7]
          : [0, 0.1, -2]
        : logoIsSmall
        ? [0.1, 2.8, -7]
        : [0, 0.1, -3],
    config: config.slow,
  });

  return (
    <animated.group dispose={null} position={position}>
      <mesh
        castShadow
        receiveShadow
        geometry={nodes.AL_LOGO1.geometry}
        material={orangeMaterial}
        scale={0.3}
      />
    </animated.group>
  );
};

useGLTF.preload("/logo/AL.gltf");
export default Model;
